import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  server: {
    VERCEL_URL: z.string().default("http://localhost:3000"),
    VERCEL_GIT_COMMIT_REF: z.string().default("local"),
    VERCEL_ENV: z.enum(["development", "preview", "production"]).default("development"),
    SHOPIFY_TOKEN: z.string(),
    NODE_ENV: z.enum(["development", "production"]).default("development"),
    BASIC_AUTH: z.string(),
    INNGEST_EVENT_KEY: z.string().optional(),
    INNGEST_SIGNING_KEY: z.string().optional(),
    VERCEL_AUTOMATION_BYPASS: z.string(),
    KLAVIYO_BACK_IN_STOCK_KEY: z.string(),
    RECAPTCHA_SECRET: z.string(),
    UPSTASH_HOST: z.string(),
    UPSTASH_TOKEN: z.string(),
    STORYBLOK_MANAGEMENT_PRIVATE_TOKEN: z.string(),
    STORYBLOK_SPACE_ID: z.string(),
    STORYBLOK_WEBHOOK_SECRET: z.string(),
    UPSTASH_HOST_CACHE_PAGINATION: z.string(),
    UPSTASH_TOKEN_CACHE_PAGINATION: z.string(),
    DOPPLER_ENVIRONMENT: z.string().default("development"),
  },
  client: {
    NEXT_PUBLIC_STORYBLOK_TOKEN: z.string(),
    NEXT_PUBLIC_SHOPIFY_URL: z.string(),
    NEXT_PUBLIC_VERCEL_URL: z.string(),
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: z.string().default("local"),
    NEXT_PUBLIC_SHOPIFY_TOKEN: z.string(),
    NEXT_PUBLIC_NODE_ENV: z.enum(["development", "production"]).default("development"),
    NEXT_PUBLIC_RECAPTCHA_KEY: z.string(),
    NEXT_PUBLIC_AXEPTIO_PROJECT_ID: z.string().optional(),
    NEXT_PUBLIC_GTM_ID: z.string(),
    NEXT_PUBLIC_REVIEWS_YOTPO_WIGDET_INSTANCE_ID: z.string(),
    NEXT_PUBLIC_STARS_YOTPO_WIGDET_INSTANCE_ID: z.string(),
    NEXT_PUBLIC_YOTPO_APP_KEY: z.string(),
    NEXT_PUBLIC_SHOPIFY_BLOG_HANDLE: z.string(),
    NEXT_PUBLIC_CACHE_ENABLED: z
      .string()
      .transform((value) => value === "true")
      .or(z.boolean()),
    NEXT_PUBLIC_ORDER_PRINTER_PRO_KEY: z.string(),
    NEXT_PUBLIC_ORDER_PRINTER_PRO_LIQUID_FILTER: z.string(),
    NEXT_PUBLIC_FASTOCHE_URL: z.string(),
    NEXT_PUBLIC_KLAVIYO_COMPANY_ID: z.string(),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(["development", "preview", "production"]).default("development"),
    NEXT_PUBLIC_KAMELEOON_SITE_CODE: z.string(),
    NEXT_PUBLIC_CHECKOUT_DOMAIN: z.string().default("checkout.bonsoirs.com"),
    NEXT_PUBLIC_CUSTOMER_ACCOUNT_SHOP_ID: z.string(),
  },
  runtimeEnv: {
    NEXT_PUBLIC_SHOPIFY_URL: process.env.NEXT_PUBLIC_SHOPIFY_URL,
    NEXT_PUBLIC_SHOPIFY_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_TOKEN,
    NEXT_PUBLIC_STORYBLOK_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
    NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    NEXT_PUBLIC_CHECKOUT_DOMAIN: process.env.NEXT_PUBLIC_CHECKOUT_DOMAIN,
    SHOPIFY_TOKEN: process.env.SHOPIFY_TOKEN,
    VERCEL_GIT_COMMIT_REF: process.env.VERCEL_GIT_COMMIT_REF,
    VERCEL_URL: process.env.VERCEL_URL,
    VERCEL_ENV: process.env.VERCEL_ENV,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    BASIC_AUTH: process.env.BASIC_AUTH,
    INNGEST_EVENT_KEY: process.env.INNGEST_EVENT_KEY,
    INNGEST_SIGNING_KEY: process.env.INNGEST_SIGNING_KEY,
    VERCEL_AUTOMATION_BYPASS: process.env.VERCEL_AUTOMATION_BYPASS,
    KLAVIYO_BACK_IN_STOCK_KEY: process.env.KLAVIYO_BACK_IN_STOCK_KEY,
    RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
    NEXT_PUBLIC_RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
    NEXT_PUBLIC_AXEPTIO_PROJECT_ID: process.env.NEXT_PUBLIC_AXEPTIO_PROJECT_ID,
    UPSTASH_HOST: process.env.UPSTASH_HOST,
    UPSTASH_TOKEN: process.env.UPSTASH_TOKEN,
    NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    NEXT_PUBLIC_REVIEWS_YOTPO_WIGDET_INSTANCE_ID: process.env.NEXT_PUBLIC_REVIEWS_YOTPO_WIGDET_INSTANCE_ID,
    NEXT_PUBLIC_STARS_YOTPO_WIGDET_INSTANCE_ID: process.env.NEXT_PUBLIC_STARS_YOTPO_WIGDET_INSTANCE_ID,
    NEXT_PUBLIC_YOTPO_APP_KEY: process.env.NEXT_PUBLIC_YOTPO_APP_KEY,
    NEXT_PUBLIC_SHOPIFY_BLOG_HANDLE: process.env.NEXT_PUBLIC_SHOPIFY_BLOG_HANDLE,
    NEXT_PUBLIC_CACHE_ENABLED: process.env.NEXT_PUBLIC_CACHE_ENABLED,
    NEXT_PUBLIC_ORDER_PRINTER_PRO_KEY: process.env.NEXT_PUBLIC_ORDER_PRINTER_PRO_KEY,
    NEXT_PUBLIC_ORDER_PRINTER_PRO_LIQUID_FILTER: process.env.NEXT_PUBLIC_ORDER_PRINTER_PRO_LIQUID_FILTER,
    NEXT_PUBLIC_FASTOCHE_URL: process.env.NEXT_PUBLIC_FASTOCHE_URL,
    NEXT_PUBLIC_KLAVIYO_COMPANY_ID: process.env.NEXT_PUBLIC_KLAVIYO_COMPANY_ID,
    STORYBLOK_MANAGEMENT_PRIVATE_TOKEN: process.env.STORYBLOK_MANAGEMENT_PRIVATE_TOKEN,
    NEXT_PUBLIC_KAMELEOON_SITE_CODE: process.env.NEXT_PUBLIC_KAMELEOON_SITE_CODE,
    STORYBLOK_SPACE_ID: process.env.STORYBLOK_SPACE_ID,
    STORYBLOK_WEBHOOK_SECRET: process.env.STORYBLOK_WEBHOOK_SECRET,
    UPSTASH_HOST_CACHE_PAGINATION: process.env.UPSTASH_HOST_CACHE_PAGINATION,
    UPSTASH_TOKEN_CACHE_PAGINATION: process.env.UPSTASH_TOKEN_CACHE_PAGINATION,
    DOPPLER_ENVIRONMENT: process.env.DOPPLER_ENVIRONMENT,
    NEXT_PUBLIC_CUSTOMER_ACCOUNT_SHOP_ID: process.env.NEXT_PUBLIC_CUSTOMER_ACCOUNT_SHOP_ID,
  },
})
