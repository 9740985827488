export const HOST_NAME = "bonsoirs.com"

export const GORGIAS_ID = "01GYCBQ5194B5REPJZ90SS76K7"

export const CARD_RATIO = "6/7" as const

export const VERIFICATION_GOOGLE_CONTENT = "KNH6zweHNqF-y1OSFl7F7bAlERCM4DIc9NhEUkb8RNs"

export const KLAVYIO_REVISION = "2023-08-15"

export * from "../revalidate/tags"
export * from "./alerts"
export * from "./cookies"
export * from "./blog"
