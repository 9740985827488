import { captureException } from "@sentry/nextjs"
import type { SafeParseError } from "zod"

import type { Nullish } from "~/@types/generics"
import { getMarket } from "~/lib/i18n/utils/get-i18n"
import isDev from "~/utils/is-dev"
import { logger } from "~/utils/logger"

/**
 * This function handles Zod safe parse errors by logging the error message and optionally throwing an
 * error.
 * @param safeError - The `safeError` parameter is a `SafeParseError` object that contains information
 * about a parsing error that occurred during validation of input data.
 * @param [context=ZodSafeError] - The context parameter is a string that is used to provide additional
 * context information when logging or throwing an error. It is optional and defaults to
 * "ZodSafeError".
 * @param [throwError=false] - `throwError` is a boolean parameter that determines whether or not to
 * throw an error when handling a `SafeParseError`. If set to `true`, the function will throw an error
 * with the error message. If set to `false` (default), the function will return `null` instead of
 * @returns `null`.
 */
export function handleZodSafeError<Input>(
  safeError: SafeParseError<Input>,
  options?: {
    errorContext?: string
    throwError?: boolean
    triggerException?: boolean
    locale?: Nullish<string>
    slug?: Nullish<string>
  }
) {
  const { errorContext = "ZodSafeParse", throwError = false, triggerException = false, locale, slug } = options ?? {}

  const errorMessage = `[${errorContext} ({${serializeContextParams({ locale, slug })}})]: ${safeError.error.message}`

  if (isDev) {
    logger.error(errorMessage)
  }

  if (triggerException) {
    captureException(errorMessage)
  }

  if (throwError) {
    throw new Error(errorMessage)
  }

  return null
}

function serializeContextParams(options: { locale: Nullish<string>; slug: Nullish<string> }) {
  return [
    options.locale ? `locale: ${options.locale}` : null,
    options.locale ? `market: ${getMarket(options.locale)}` : null,
    options.slug ? `slug: ${options.slug}` : null,
  ]
    .filter(Boolean)
    .join("; ")
}
