import type { z, ZodSchema } from "zod"

import type { ExplicitAny, Nullish } from "~/@types/generics"
import { handleZodSafeError } from "~/lib/zod/utils/handle-zod-safe-error"

export function handleZodSafeParse<Schema extends ZodSchema, Data extends Record<string, ExplicitAny>>(
  schema: Schema,
  data: Nullish<Data>,
  options?: {
    errorContext?: string
    throwError?: boolean
    triggerException?: boolean
    locale?: Nullish<string>
    slug?: Nullish<string>
  }
) {
  const parsedData = schema.safeParse(data)

  if (parsedData.success === false) {
    return handleZodSafeError(parsedData, options)
  }

  return parsedData.data as z.infer<Schema>
}
